var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    { ref: "ruleForm", attrs: { model: _vm.productFormData } },
    [
      _c(
        "div",
        { staticClass: "flex-between-row margin-right-10" },
        [
          _c("h1", { staticClass: "sub-module-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm.termContractType === _vm.TERM_CONTRACT_TYPE.DELIVERED
            ? _c(
                "el-button",
                {
                  staticClass: "float-right",
                  attrs: {
                    type: "primary",
                    round: "",
                    size: "mini",
                    disabled: _vm.isAllFieldsDisabled,
                  },
                  on: { click: _vm.addNewProductLine },
                },
                [_vm._v(" Add ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "sub-module-block-table" }, [
        _c(
          "div",
          { staticClass: "div-product-other-costs-info-body" },
          [
            _c(
              "el-table",
              {
                ref: "productTable",
                attrs: { data: _vm.productFormData.products, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "No.", width: "50px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("h5", { staticClass: "h5-el-table-col-index" }, [
                            _vm._v(_vm._s(scope.$index + 1)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "300px" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", [_vm._v("* ")]),
                          _vm._v("Product Name"),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop:
                                  "products." + scope.$index + ".sysProductId",
                                rules: _vm.rules.reqRule,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.isAllFieldsDisabled ||
                                      !_vm.hasEditPermission,
                                  },
                                  model: {
                                    value: scope.row.sysProductId,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sysProductId", $$v)
                                    },
                                    expression: "scope.row.sysProductId",
                                  },
                                },
                                _vm._l(
                                  _vm.sysProductOptionList,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                [
                  _vm.TERM_CONTRACT_TYPE.EX_WHARF,
                  _vm.TERM_CONTRACT_TYPE.DELIVERED,
                ].includes(_vm.termContractType)
                  ? _c("el-table-column", {
                      attrs: { "min-width": "200px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("span", [_vm._v("* ")]),
                                _vm._v("Specs"),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "products." +
                                        scope.$index +
                                        ".sysProductSpecificationId",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.isAllFieldsDisabled ||
                                            !_vm.hasEditPermission,
                                        },
                                        model: {
                                          value:
                                            scope.row.sysProductSpecificationId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sysProductSpecificationId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.sysProductSpecificationId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.sysPproductSpecsOptionList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        427669349
                      ),
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: { "min-width": "150px" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("Min Qty")]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop:
                                  "products." +
                                  scope.$index +
                                  ".minimumQuantity",
                                rules: _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  precision: 3,
                                  min: null,
                                  disabled:
                                    _vm.isAllFieldsDisabled ||
                                    !_vm.hasEditPermission,
                                },
                                model: {
                                  value: scope.row.minimumQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "minimumQuantity", $$v)
                                  },
                                  expression: "scope.row.minimumQuantity",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "Max Qty", "min-width": "150px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop:
                                  "products." +
                                  scope.$index +
                                  ".maximumQuantity",
                                rules: _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  precision: 3,
                                  min: null,
                                  disabled:
                                    _vm.isAllFieldsDisabled ||
                                    !_vm.hasEditPermission,
                                },
                                model: {
                                  value: scope.row.maximumQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "maximumQuantity", $$v)
                                  },
                                  expression: "scope.row.maximumQuantity",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "150px" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", [_vm._v("* ")]),
                          _vm._v("Unit Metric"),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop:
                                  "products." + scope.$index + ".unitMetric",
                                rules: _vm.rules.reqRule,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.isAllFieldsDisabled ||
                                      !_vm.hasEditPermission,
                                  },
                                  model: {
                                    value: scope.row.unitMetric,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "unitMetric", $$v)
                                    },
                                    expression: "scope.row.unitMetric",
                                  },
                                },
                                _vm._l(
                                  _vm.UNIT_OPTIONS.filter(
                                    (item) => item.label !== "%"
                                  ),
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "150px" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_vm._v("Price")]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "products." + scope.$index + ".price",
                                rules: _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  precision: 3,
                                  min: null,
                                  disabled:
                                    _vm.isAllFieldsDisabled ||
                                    !_vm.hasEditPermission,
                                },
                                model: {
                                  value: scope.row.price,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "price", $$v)
                                  },
                                  expression: "scope.row.price",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "Currency", "min-width": "120px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "products." + scope.$index + ".currency",
                                rules: _vm.rules.notReqRule,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: scope.row.currency,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "currency", $$v)
                                    },
                                    expression: "scope.row.currency",
                                  },
                                },
                                _vm._l(_vm.currencyOptionList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.code,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm.termContractType !== _vm.TERM_CONTRACT_TYPE.EX_WHARF
                  ? _c("el-table-column", {
                      attrs: { "min-width": "250px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("span", [_vm._v("* ")]),
                                _vm._v("Physical Supplier BDN"),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "products." +
                                        scope.$index +
                                        ".physicalSupplierSysOrganizationId",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          "value-key": "id",
                                          disabled:
                                            _vm.isAllFieldsDisabled ||
                                            !_vm.hasEditPermission,
                                        },
                                        model: {
                                          value:
                                            scope.row
                                              .physicalSupplierSysOrganizationId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "physicalSupplierSysOrganizationId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.physicalSupplierSysOrganizationId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.physicalSupplierList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.companyName,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3000938749
                      ),
                    })
                  : _vm._e(),
                [
                  _vm.TERM_CONTRACT_TYPE.EX_WHARF,
                  _vm.TERM_CONTRACT_TYPE.DELIVERED,
                ].includes(_vm.termContractType)
                  ? _c("el-table-column", {
                      attrs: { label: "Price Type", "min-width": "150px" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "products." +
                                        scope.$index +
                                        ".priceType",
                                      rules: _vm.rules.reqRule,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.isAllFieldsDisabled ||
                                            !_vm.hasEditPermission,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handlePriceTypeSelect(
                                              $event,
                                              scope.row,
                                              "BUYER",
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value: scope.row.priceType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "priceType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.priceType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.ORDER_PRICE_TYPE_OPTIONS,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1807557916
                      ),
                    })
                  : _vm._e(),
                _vm.termContractType === _vm.TERM_CONTRACT_TYPE.DELIVERED
                  ? _c("el-table-column", {
                      attrs: { label: "", width: "90px", fixed: "right" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      "confirm-button-text": "Delete",
                                      "cancel-button-text": "No, Thanks",
                                      icon: "el-icon-info",
                                      "icon-color": "red",
                                      title: "Are you sure to delete this?",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.removeProduct(scope.$index)
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        slot: "reference",
                                        size: "small",
                                        icon: "el-icon-delete",
                                        type: "danger",
                                        circle: "",
                                        disabled:
                                          _vm.isAllFieldsDisabled ||
                                          _vm.productFormData.products
                                            .length === 1,
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1027873831
                      ),
                    })
                  : _vm._e(),
                [
                  _vm.TERM_CONTRACT_TYPE.EX_WHARF,
                  _vm.TERM_CONTRACT_TYPE.DELIVERED,
                ].includes(_vm.termContractType)
                  ? _c("el-table-column", {
                      attrs: {
                        type: "expand",
                        "min-width": "50px",
                        width: "auto",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("table-col-product-pricing", {
                                  attrs: {
                                    parent: "ProductForm",
                                    propsView: _vm.view,
                                    isAllFieldsDisabled:
                                      _vm.isAllFieldsDisabled,
                                    rulesReq: _vm.rules.reqRule,
                                    notReqRule: _vm.rules.notReqRule,
                                    scopeRow: scope.row,
                                    scopeIndex: scope.$index,
                                    priceType: scope.row.priceType,
                                    priceMethodologyList:
                                      _vm.priceMethodologyList,
                                    holidayMap: _vm.holidayMap,
                                    resetProductPriceWatcher:
                                      _vm.resetProductPriceWatcher,
                                    priceProp: "products",
                                  },
                                  on: {
                                    handleReferencePriceChange:
                                      _vm.handleReferencePriceChange,
                                    handlePriceInfoChange:
                                      _vm.handlePriceInfoChange,
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3230185858
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }